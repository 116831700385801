import React, { useEffect, useState } from 'react';
import css from './styles.module.scss'
import androidLogo from '../download/android.png'
import iosLogo from '../download/ios.png'
import { http } from 'libs';
import logo from './logo.png'

export default function () {
  const [data, setData] = useState({})

  const ua = navigator.userAgent
  const isAndroid = () => ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1
  const isIOS = () => { 
    return /macintosh|mac os x/i.test(navigator.userAgent) || !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  }
  const getLogo = () => { 
    return isIOS() ? iosLogo : androidLogo
  }

  useEffect(() => {
    document.title = `十愿网`
    // document.getElementsByName('keywords')[0].content = `十愿网`
    // document.getElementsByName('description')[0].content = `十愿网`

    http.post('/web/index/download')
      .then(res => setData(res))
  }, [])

  return (
    <div className={css.box}>
      <img className={ css.logo } src={ logo } />
      <div className={css.ft}>
        <a className={css.btn1} href={data.android_download_link}><img src={ getLogo() } alt="" />App下载</a>
        <a className={css.btn2} href='https://apps.apple.com/app/id1596314940'><img src={iosLogo} alt=""/>IOS下载</a>
      </div>
    </div>
  )
}
