import React  from 'react';
import css from './index.module.scss'
import icd from "./pic/ic_d.png";
import iczs from "./pic/ic_zs2.png";
import more from "./pic/more.png";
import top1 from "./pic/ic_top1.png"
import top2 from "./pic/ic_top2.png"

export default function List2(props) {
  return <>
    <div className={css.listHd}>
      <img className={css.pic1} src={icd} alt=""/>
      <span className={css.tit}>视频</span>
      <img className={css.pic2} src={iczs} alt=""/>
      <span className='flex1'/>
      {/*<img className={css.more} src={more} alt=""/>*/}
    </div>
    <div className={css.list2}>
      {props.data.map((item, i) =>
        i < 2 ?
          <div className={css.item} key={i}>
            <a href={`/detail_video/${item.a_id}`} className={css.pic} target="_blank" rel="noreferrer">
              <img className={css.img} src={item.a_cover_url[0]} alt=""/>
              <div className={css.picDesc}>{item.a_title}</div>
              <div className={css.time}>{item.a_video_duration}</div>
              <div className={css.pm}>
                <img src={i === 0 ? top1 : top2} alt=""/>
              </div>
            </a>
          </div>
          :
          <div className={css.item} key={i}>
            <a href={`/detail_video/${item.a_id}`} className={css.picSm} target="_blank" rel="noreferrer">
              <img src={item.a_cover_url[0]} alt=""/>
              <div className={css.pm}>{i + 1}</div>
              <div className={css.time}>{item.a_video_duration}</div>
            </a>
            <div className={css.info}>
              <a href={`/detail_video/${item.a_id}`} className={css.title} target="_blank" rel="noreferrer">{item.a_title}</a>
              <div className={css.desc}>
                <span>{item.a_read_count}次观看</span>
                <span>{item.nickname}</span>
              </div>
            </div>
          </div>
      )}
    </div>
  </>
}
