import React, { useEffect, useState } from "react";
import http from "libs/http";
import css from './index.module.scss';

let nextPage = 1

export default function Comments(props) {

  const [list, setList] = useState([])
  const [fetching, setFetching] = useState(true)
  const [page, setPage] = useState(1)

  useEffect(() => {
    const params = { t_id: props.tid, page }
    if (window.location.search.indexOf('token') > -1) {
      const str = window.location.search.substring(window.location.search.indexOf('?') + 1)
      const token = str.split('=')[1] || ''
      if (token) {
        params.token = token
      }
    }
    setFetching(true)
    http.post('/web/topic/comment', params)
      .then(res => {
        _updateArticles(res.data, res.last_page)
      })
      .finally(() => setFetching(false))
  }, [page])

  function _updateArticles(data, lastPage) {
    if (page < lastPage) nextPage = page + 1
    if (page === 1) {
      setList(data)
    } else {
      setList(list.concat(data))
    }
  }
  

  return (
    <div>
      <div className={css.commentsList}>
        {list.map(item => (<div key={item.ac_id} className={css.item}>
          <div className={css.hd}>
            <img src={item.avatar} alt=""/>
            <div className={css.t}>
              <span className={css.userName}>{item.nickname}</span>
              <span className={css.time}>· {item.tc_create_time_str}</span>
            </div>
          </div>
          <div className={css.detail}>
            <div className={css.comment}>{item.tc_content}</div>
          </div>
        </div>))}
        {
          fetching ? (
            <div className="textLoading">正在获取更多内容</div>
          ) : (
                list.length > 0 ? (
                  <div className="textLoading" style={{ cursor: 'pointer' }} onClick={() => setPage(nextPage)}>加载更多</div>
                ) : ''
          )
        }
        {list.length === 0 && (<div className={css.nodata}>暂无评论</div>)}
      </div>
    </div>
  )
}