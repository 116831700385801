import React  from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import MobileIndex from './pages/mobile/home';
import MobileNews from './pages/mobile/news';
import MobileWiki from './pages/mobile/wiki';
import MobileTopic from './pages/mobile/topic';
import Special from './pages/mobile/special'
import Download from './pages/mobile/download'
import About from './pages/mobile/about'
import Search from './pages/mobile/search'
import Layout from './layout';
import { isMobile, history, http } from 'libs';

function App(props) {
  const { pathname } = useLocation();  // 存储当前路由地址`
  
  const jumpArticleForPc = path => {
    const path_arr = path.replace('/', '').split('/')
    if (path_arr.length !== 3) return
    const a_id = (path_arr[2] || '').replace(/\s+/g, '')
    http.get('/web/share/article', {params: {a_id: a_id}})
      .then(res => {
        if (res) {
          const urlCfg = {
            1: `/detail/${a_id}`,
            2: `/detail_video/${a_id}`,
            3: `/detail_atlas/${a_id}`
          }
          if (urlCfg[res.a_type]) {
            history.replace(urlCfg[res.a_type])
          }
        }
      })
  }

  if (isMobile) {
    switch (true) {
      case pathname.substr(0, 6) === '/cate/':
      case pathname === '/':
        history.replace('/m')
        break
      case pathname === '/download':
        history.replace('/m/download')
        break
      case pathname === '/about':
        history.replace('/m/about')
        break
      case pathname === '/search':
        history.replace('/m/search')
        break
      case pathname.startsWith('/topic/'):
        history.replace(pathname.replace('/topic/', '/m/topic/'))
        break
      case pathname.substr(0, 7) === '/detail':
        const fields = pathname.split('/')
        if (fields.length === 3) {
          history.replace(`/m/news/${fields[2]}`)
        }
        break
      default:
        break
    }
  } else {
    console.log(pathname)
    switch (true) {
      case pathname === '/m':
        history.replace('/')
        break
      case pathname === '/m/download':
        history.replace('/download')
        break
      case pathname === '/m/about':
        history.replace('/about')
        break
      case pathname === '/m/search':
        history.replace('/search')
        break
      case pathname.startsWith('/m/topic/'):
        history.replace(pathname.replace('/m/topic/', '/topic/'))
        break
      case pathname.startsWith('/m/news/'):
        jumpArticleForPc(pathname)
        break
      default:
        break
    }
  }

  return (
    <Switch>
      <Route path="/m" exact component={MobileIndex}/>
      <Route path="/m/news/:id" exact component={MobileNews} />
      <Route path="/m/topic/:id" exact component={MobileTopic} />
      <Route path="/m/wiki/:id" exact component={MobileWiki}/>
      <Route path="/m/special/:id" exact component={Special}/>
      <Route path="/m/download" exact component={Download}/>
      <Route path="/m/about" exact component={About}/>
      <Route path="/m/search" exact component={Search}/>
      <Route component={Layout}/>
    </Switch>
  );
}

export default App;
