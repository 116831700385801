import React from 'react';
import css from './index.module.scss'

export default function Top(props) {
  return (
    <div className={css.topNews}>
      <div className={css.line}/>
      {props.data.map((item, index) => (
        <a key={index}
           rel="noreferrer"
           className={css.item}
           style={{cursor: item.awt_url ? 'pointer' : 'auto'}}
           href={item.awt_url ?? ''}
           target="_blank"
        >
          <div className={css.pic}>
            <img src={item.awt_cover_url} alt=""/>
            <div className={css.cover}/>
          </div>
          <div className={css.info}>
            <div className={css.tit}>{item.awt_title}</div>
            <div className={css.desc}>{item.awt_desc}</div>
          </div>
        </a>
      ))}
    </div>
  )
}
