import React from 'react';
import css from './styles.module.scss'

export default function (props) {
  const {record} = props

  return (
    <div className={css.wifiPage}>
      {
        record.b_cover ? <img src={record.b_cover} className={css.banner} alt=""/> : null
      }
      <div className={[css.infoCard,record.b_cover?'':css.static].join(" ")}>
        <div className={css.title}>{record.b_title}</div>
        {
          record.bc_name ? <div className={css.where}>{record.bc_name}</div> : null
        }
        <div className={css.note}>{record.b_intro}</div>
        <div className={css.basic}>
          <div className={css.t}>基本信息</div>
          {
            record.b_base_field ? record.b_base_field.map((v, i) => (
              <div className={css.p} key={i}>
                <div className={css.label}>{v.bb_key}</div>
                <div className={css.txt}>{v.bb_value}</div>
              </div>
            )) : null
          }
        </div>
      </div>

      <div className={css.detail}>
        <div className={css.p} dangerouslySetInnerHTML={{__html: record.b_main_body}}/>

        <div className={css.tit}>参考资料</div>
        <div className={css.ps}>
          {
            record.b_quote && record.b_quote.length ? record.b_quote.map((v, i) => (
              <div className={css.quote}>
                <div>{i + 1}. <a href={v.bq_url}>{v.bq_title} 参考来源：{v.bq_source} {v.bq_date}</a></div>
              </div>
            )) : <div>无</div>
          }
        </div>
      </div>
    </div>
  )
}
