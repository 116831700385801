import React, { useEffect, useState } from 'react';
import { history, http } from 'libs';
import List3 from './List3'
import css from './index.module.scss'
import wx from './pic/wx.png'
import icd from "./pic/ic_d.png";
import iczs from "./pic/ic_zs2.png";
import moment from 'moment';
import QRCode from "qrcode.react";
import qzone from "./pic/qzone.png";

export default function (props) {
  const {id} = props.match.params
  const [article, setArticle] = useState({comments: [], video_recommends: []})

  useEffect(() => {
    http.get('/web/share/article', {params: {a_id: id}})
      .then(res => {
        document.title= `${res.a_title}_十愿网`
        // document.getElementsByName('keywords')[0].content = `${res.a_title}, 十愿网`
        // document.getElementsByName('description')[0].content = `${res.a_title}`

        setArticle(res)
        window.Aliplayer({
          id: 'video',
          width: '1100px',
          height: '618px',
          source: res.a_video_url,
          cover: res.a_cover_url[0],
          skinLayout: [
            {name: "bigPlayButton", align: "blabs", x: 'calc(50% - 32px)', y: 'calc(50% - 32px)'},
            {
              name: "H5Loading", align: "cc"
            },
            {name: "errorDisplay", align: "tlabs", x: 0, y: 0},
            {name: "infoDisplay"},
            {name: "tooltip", align: "blabs", x: 0, y: 56},
            {name: "thumbnail"},
            {
              name: "controlBar", align: "blabs", x: 0, y: 0,
              children: [
                {name: "progress", align: "blabs", x: 0, y: 44},
                {name: "playButton", align: "tl", x: 15, y: 12},
                {name: "timeDisplay", align: "tl", x: 10, y: 7},
                {name: "fullScreenButton", align: "tr", x: 10, y: 12},
                {name: "setting", align: "tr", x: 15, y: 12},
                {name: "volume", align: "tr", x: 5, y: 10}
              ]
            }
          ]
        })
      })
  }, [id])

  function renderQrcode() {
    return <div className={css.qrcodeBox}>
      <div className={css.qrcode}>
        <QRCode
          value={window.location.origin + '/m/news/' + id}
          size={80} // 二维码的大小
          fgColor="#000000" // 二维码的颜色
          style={{margin: 'auto'}}
        />
      </div>
      <p>微信扫码分享</p>
    </div>
  }

  function gotoHome(){
    history.push(`/cate/${article.c_alias}`)
  }

  return (
    <div className={css.home}>
      {
        article.c_name ? <div className={css.breadcrumb}><a onClick={gotoHome}>{article.c_name}</a> > 正文</div> : ''
      }
      <div className={css.article}>
        <div className={css.title}>{article.a_title}</div>
        <div className={css.info}>
          <div className={css.l}>
            <div>{moment(article.a_create_time * 1000).format('YYYY年MM月DD日 HH:mm:ss')}</div>
            <div>{article.nickname}</div>
          </div>
          <a className={css.r} target='_blank' title='分享到QQ空间' href={`https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=https://www.10vows.com/detail/${id}&title=${article.share_title}&pics=${article.share_img}`}>
            <img src={qzone} alt=""/>
          </a>
          <div className={css.r}>
            <img src={wx} alt=""/>
            {renderQrcode()}
          </div>
        </div>

        <div id="video" className={css.video}/>

      </div>

      <List3 data={article.video_recommends}/>

      <div className={css.comments}>
        <div className={css.listHd}>
          <img className={css.pic1} src={icd} alt=""/>
          <span className={css.tit}>全部评论</span>
          <img className={css.pic2} src={iczs} alt=""/>
        </div>

        <div className={css.commentsList}>
          {article.comments.map(item => (
            <div key={item.ac_id} className={css.item}>
              <div className={css.hd}>
                <img src={item.avatar} alt=""/>
                <div className={css.t}>
                  <span className={css.userName}>{item.nickname}</span>
                  <span className={css.time}>· {item.ac_create_time_str}</span>
                </div>
              </div>
              <div className={css.detail}>
                <div className={css.comment}>{item.ac_content}</div>
              </div>
            </div>
          ))}
          {article.a_id && article.comments.length === 0 && (
            <div className={css.nodata}>暂无评论</div>
          )}
        </div>
      </div>
    </div>
  )
}
