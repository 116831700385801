import React from 'react';
import css from './index.module.scss'
import play1 from "../home/pic/play1.png";

export default function List(props) {
  function renderUrl(item) {
    const urlCfg = {
      1: `/detail/${item.a_id}`,
      2: `/detail_video/${item.a_id}`,
      3: `/detail_atlas/${item.a_id}`
    }
    return urlCfg[item.a_type]
  }
  //文章标题样式。0:无图；1:左文右图；2:三图；3:左文右图；4:视频
  return (
    <div className={css.list}>
      {props.data.map(item => (
        <a className={css.item} key={item.a_id} href={renderUrl(item)} target="_blank" rel="noreferrer">
          {[1, 3, 4].includes(item.a_title_style) ? (
            <>
              <div className={css.pic}>
                <img src={item.a_cover_url[0]} alt=""/>
                {item.a_title_style === 4 && <div className={css.play}><img src={play1} alt=""/></div>}
              </div>
              <div className={css.info}>
                <a className={css.title}>{item.a_title}</a>
                <div className={css.desc}>
                  {item.nickname}
                  <span className={css.dot}/>
                  {item.a_comment_count}评论
                  <span className={css.dot}/>
                  {item.a_create_time_format}
                </div>
              </div>
            </>
          ) : item.a_title_style === 2 ? (
            <div className={css.info}>
              <a className={css.title}>{item.a_title}</a>
              <div className={css.pics}>
                {item.a_cover_url.slice(0, 3).map((item, index) => (
                  <img key={index} src={item} alt=""/>
                ))}
              </div>
              <div className={css.desc}>
                {item.nickname}
                <span className={css.dot}/>
                {item.a_comment_count}评论
                <span className={css.dot}/>
                {item.a_create_time_format}
              </div>
            </div>
          ) : (
            <div className={css.info}>
              <a className={css.title}>{item.a_title}</a>
              <div className={css.pics}/>
              <div className={css.desc}>
                {item.nickname}
                <span className={css.dot}/>
                {item.a_comment_count}评论
                <span className={css.dot}/>
                {item.a_create_time_format}
              </div>
            </div>
          )}
        </a>
      ))}
    </div>
  )
}
