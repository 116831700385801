import React, { useEffect, useState } from 'react';
import { http } from 'libs';
import List from '../home/List'
import css from './index.module.scss'
import searchPic from "../../layout/search.png";

let nextPage = 0

export default function (props) {
  const params = new URLSearchParams(props.location.search)
  const [type, setType] = useState(1)
  const [articles, setArticles] = useState([])
  const [fetching, setFetching] = useState(true)
  const [page, setPage] = useState(1)
  const [keyword, setKeyword] = useState(params.get('keyword'))

  useEffect(() => {
    setFetching(true)
    http.post('/web/article/search', {page, keyword, a_type: type})
      .then(res => _updateArticles(res.data, res.last_page))
      .finally(() => setFetching(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, type])

  useEffect(() => {
    document.title = `搜索_十愿网`
    // document.getElementsByName('keywords')[0].content = `搜索, 十愿网`
    // document.getElementsByName('description')[0].content = `搜索`

    window.addEventListener('scroll', _loadMore)
    return () => {
      window.removeEventListener('scroll', _loadMore)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function _loadMore() {
    const scrollTop = document.documentElement.scrollTop
    const clientHeight = document.documentElement.clientHeight
    const scrollHeight = document.documentElement.scrollHeight
    if (scrollHeight === clientHeight || !nextPage) return
    if (scrollHeight - scrollTop - clientHeight < 300) {
      setPage(nextPage)
      nextPage = 0
    }
  }

  function _updateArticles(data, lastPage) {
    if (page < lastPage) nextPage = page + 1
    if (page === 1) {
      setArticles(data)
    } else {
      setArticles(articles.concat(data))
    }
  }

  function handleTypeChange(type) {
    setType(type)
    setPage(1)
  }

  return (
    <div className={css.home} onScrollCapture={e => console.log(e)}>
      <form action="/search" className={css.search}>
        <input name='keyword' value={keyword} onChange={e => setKeyword(e.target.value)} type="text"
               placeholder='请输入搜索内容'/>
        <button type='submit' className={css.btn}>
          <img src={searchPic} alt=""/>
        </button>
      </form>
      <div className={css.types}>
        <a className={type === 1 ? css.cur : ''} onClick={() => handleTypeChange(1)}>文章</a>
        <a className={type === 2 ? css.cur : ''} onClick={() => handleTypeChange(2)}>视频</a>
      </div>

      <div className={css.listBox}>
        <List data={articles}/>
        {fetching && <div className="textLoading">正在获取更多内容</div>}
        {!fetching && articles.length === 0 && <div className={css.tips}>暂无内容，请换个关键词搜索吧</div>}
      </div>
    </div>
  )
}
