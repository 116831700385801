import React from 'react';
import { history } from 'libs';
import css from './index.module.scss'
import play1 from './pic/play1.png'
import play2 from './pic/play2.png'

export default function ListVideo(props) {
  function handleClick(a_id) {
    history.push(`/m/news/${a_id}`)
  }

  return (
    <div className={css.listVideo}>
      {
        props.data.map((item) =>
          <div key={item.a_id} className={css.item} onClick={() => handleClick(item.a_id)}>
            <div className={css.pic}>
              <img className={css.bg} src={item.a_cover_url[0]} alt=""/>
              <div className={css.play}><img src={play1} alt=""/></div>
              <div className={css.time}>
                <img src={play2} alt=""/>
                <span>{item.a_read_count}</span>
              </div>
            </div>
            <div className={css.info}>
              <a href='' className={css.title}>{item.a_title}</a>
              <div className={css.desc}>
                <span>{item.nickname}</span>
                <span>{item.a_create_time_format}</span>
              </div>
            </div>
          </div>
        )
      }
      <div className={css.space}/>
      <div className={css.space}/>

    </div>
  )
}
