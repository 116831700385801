import React from 'react';
import { human_timeStr } from "libs";
import css from './styles.module.scss'
import hdPic from './head.png'

export default function (props) {
  const {record} = props

  return (
      <div className={css.newsBox}>
        <div className={css.title}>{record.a_title}</div>
        <div className={css.tools}>
          <div className={css.author}>
            <img className={css.headPic} src={record.avatar||hdPic} alt=""/>
            <div>
              <div className={css.name}>{record.nickname||'匿名'}</div>
              <div className={css.time}>{human_timeStr(record.a_create_time*1000)}发布</div>
            </div>
          </div>
          {/* <div className={css.focus}>＋ 关注</div> */}
        </div>
        <div className={css.detailBox}>
          <div className={css.p} dangerouslySetInnerHTML={{__html: record.a_content}}/>
        </div>
      </div>
  )
}
