import React, { useEffect, useState } from "react";
import { human_timeStr } from 'libs'
import css from './index.module.scss'
import hdPic from './head.png'
import http from "libs/http";

let nextPage = 1

export default function (props) {
  
  const [record, setRecord] = useState([])
  const [fetching, setFetching] = useState(true)
  const [page, setPage] = useState(1)

  useEffect(() => {
    const params = { t_id: props.tid, page }
    if (window.location.search.indexOf('token') > -1) {
      const str = window.location.search.substring(window.location.search.indexOf('?') + 1)
      const token = str.split('=')[1] || ''
      if (token) {
        params.token = token
      }
    }
    setFetching(true)
    http.post('/web/topic/comment', params)
      .then(res => {
        _updateArticles(res.data, res.last_page)
      })
      .finally(() => setFetching(false))
  }, [page])

  function _updateArticles(data, lastPage) {
    if (page < lastPage) nextPage = page + 1
    if (page === 1) {
      setRecord(data)
    } else {
      setRecord(record.concat(data))
    }
  }

  return (
      <div className={css.commentBox}>
        <div className={css.htitle}>热门评论</div>
        <div className={css.list}>
          {
            record.map((v, i) => (
                <div key={i} className={css.citem}>
                  <div className={css.hd}>
                    <img className={css.headPic} src={v.avatar || hdPic} alt=""/>
                    <div className={css.name}>{v.nickname}</div>
                  </div>
                  <div className={css.bd}>{v.tc_content}</div>
                  {/* <div className={css.ft}>{human_timeStr(v.ac_create_time * 1000)}</div> */}
                </div>
            ))
        }
        {
          fetching ? (
            <div className="textLoading">正在获取更多内容</div>
          ) : (
              record.length > 0 ? (
                <div className="textLoading" style={{ cursor: 'pointer' }} onClick={() => setPage(nextPage)}>加载更多</div>
              ) : ''
          )
        }
        {record.length === 0 && (<div className={css.nodata}>暂无评论</div>)}
        </div>
      </div>
  )
}
