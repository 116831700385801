import React, { useEffect, useState } from 'react';
import TextNews from "./TextNews";
import VideoNews from "./VideoNews";
import PicNews from './PicNews';
import Comment from "./Comment";
import css from './styles.module.scss'
import { http } from "libs";
import FooterDownload from '../common/FooterDownload';

export default function (props) {
  const {id} = props.match.params
  const [loading, setLoading] = useState(true)
  const [record, setRecord] = useState([])

  useEffect(() => {
    console.log(window.location)
    http.post('/web/share/jssdk', {url: window.location.href})
      .then(res => {
        window.wx.config(res)
      })
  }, [])

  useEffect(() => {
    setLoading(true)
    http.get('/web/share/article', {params: {a_id: id}})
      .then(res => {
        setRecord(res)
        document.title= `${res.a_title}_十愿网`
        // document.getElementsByName('keywords')[0].content = `${res.a_title}, 十愿网`
        // document.getElementsByName('description')[0].content = `${res.a_title}`

        window.wx.ready(() => {
          window.wx.updateAppMessageShareData({
            title: res.share_title,  // 分享标题
            desc: res.share_content, // 分享描述
            link: res.share_url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: res.share_img
          })
          window.wx.updateTimelineShareData({
            title: res.share_title,  // 分享标题
            link: res.share_url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: res.share_img
          })
        })
      }).finally(() => setLoading(false))
    // s.fetchRecords(id).then(() => setLoading(false))
  }, [id])


  //int	文章类型。1:图文：2:视频 3：图集。
  return (
    <>
      {
        loading ? <div className={css.loadEffect}>
          <span/>
          <span/>
          <span/>
          <span/>
          <span/>
          <span/>
          <span/>
          <span/>
        </div> : <>
          {
              record.a_type == 1
                ? <TextNews record={record} />
                : (
                  record.a_type === 2 ? <VideoNews record={record} /> : <PicNews record={record} />
                )
          }
            <Comment record={record} />
            <FooterDownload />
        </>
      }
      

    </>
  )
}
