import React from 'react';
import { human_timeStr } from 'libs'
import css from './styles.module.scss'
import hdPic from './head.png'

export default function (props) {
  const {record} = props

  return (
      <div className={css.commentBox}>
        <div className={css.title}>最新评论</div>
        <div className={css.list}>
          {
            record.comments && record.comments.length ? record.comments.map((v, i) => (
                <div key={i} className={css.item}>
                  <div className={css.hd}>
                    <img className={css.headPic} src={v.avatar || hdPic} alt=""/>
                    <div className={css.name}>{v.nickname}</div>
                  </div>
                  <div className={css.bd}>{v.ac_content}</div>
                  <div className={css.ft}>{human_timeStr(v.ac_create_time * 1000)}</div>
                </div>
            )) : <div className={css.nodata}>暂无评论</div>
          }
        </div>
      </div>
  )
}
