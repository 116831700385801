import React from 'react';
import css from './index.module.scss'
import icd from "./pic/ic_d.png";
import iczs from "./pic/ic_zs2.png";
import play1 from "../home/pic/play1.png";
import play2 from "../home/pic/play2.png";

export default function List3(props) {
  return <>
    <div className={css.listHd} style={{marginTop:50}}>
      <img className={css.pic1} src={icd} alt=""/>
      <span className={css.tit}>相关推荐</span>
      <img className={css.pic2} src={iczs} alt=""/>
    </div>

    <div className={css.listVideo}>
      {props.data.map((item) =>
        <a key={item.a_id} className={css.item} href={`/detail_video/${item.a_id}`} target="_blank" rel="noreferrer">
          <div className={css.pic}>
            <img className={css.bg} src={item.a_cover_url[0]} alt=""/>
            <div className={css.play}><img src={play1} alt=""/></div>
            <div className={css.time}>
              <img src={play2} alt=""/>
              <span>{item.a_read_count}</span>
            </div>
          </div>
          <div className={css.info}>
            <a href='#' className={css.title}>{item.a_title}</a>
            <div className={css.desc}>
              <span>{item.nickname}</span>
              <span>{item.a_create_time_format}</span>
            </div>
          </div>
        </a>
      )}
      <div className={css.space}/>
      <div className={css.space}/>
      <div className={css.space}/>
    </div>
  </>
}
