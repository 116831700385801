import React, { useEffect, useState } from 'react';
import autoplay from 'swiper/modules/autoplay/autoplay.js';
import pagination from 'swiper/modules/pagination/pagination.js';
import { Swiper, } from 'swiper/react/swiper.js';// Import Swiper React components
import { SwiperSlide } from 'swiper/react/swiper-slide.js';// Import Swiper React components
import { http } from 'libs';
import List from './List'
import ListVideo from './ListVideo'
import ListTopic from './ListTopic';
import Header from '../common/Header'
import Footer from '../common/Footer'
import css from './index.module.scss'
import 'swiper/swiper.scss';// Import Swiper styles
import 'swiper/modules/autoplay/autoplay.scss';// Import Swiper styles
import 'swiper/modules/pagination/pagination.scss';// Import Swiper styles

let nextPage = 0

export default function () {
  const [type, setType] = useState({c_alias: 'index_recommend', c_name: '首页'})
  const [types, setTypes] = useState([])
  const [ads, setAds] = useState([])
  const [tops, setTops] = useState([])
  const [articles, setArticles] = useState([])
  const [fetching, setFetching] = useState(true)
  const [page, setPage] = useState(1)
  const params = {enabled: true, delay: 3000, pauseOnMouseEnter: true, disableOnInteraction: false}

  useEffect(() => {
    http.get('/web/index/category')
      .then(res => setTypes(res.data))
  }, [])

  useEffect(() => {
    setFetching(true)
    if (type.c_alias === 'index_recommend') {
      fetchRecommends().finally(() => setFetching(false))
    } else if (type.c_alias === 'index_video') {
      http.post('/web/article/videos', {page})
        .then(res => _updateArticles(res.data, res.last_page))
        .finally(() => setFetching(false))
    } else if (type.c_alias === 'index_topic') {
      http.post('/web/topic/lists', { page })
        .then(res => _updateArticles(res.data, res.last_page))
        .finally(() => setFetching(false))
    } else {
      http.post('/web/article/lists', {page, c_alias: type.c_alias})
        .then(res => _updateArticles(res.data, res.last_page))
        .finally(() => setFetching(false))
    }

    if(type.c_alias === 'index_recommend'){
      document.title= `十愿网`
      // document.getElementsByName('keywords')[0].content = `十愿网`
      // document.getElementsByName('description')[0].content = `十愿网`
    } else {
      let typeName = type.c_name
      document.title= `${typeName}_十愿网`
      // document.getElementsByName('keywords')[0].content = `${typeName}, 十愿网`
      // document.getElementsByName('description')[0].content = `${typeName}`
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, type])

  useEffect(() => {
    window.addEventListener('scroll', _loadMore)
    return () => {
      window.removeEventListener('scroll', _loadMore)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    http.post('/web/share/jssdk', {url: window.location.href})
      .then(res => {
        window.wx.config(res)
        let desc = document.getElementsByName('description')[0].getAttribute('content')
        window.wx.ready(() => {
          window.wx.updateAppMessageShareData({
            title: document.title,  // 分享标题
            desc: desc, // 分享描述
            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: "https://res.10vows.com/static/10vows/favicon.ico"
          })
          window.wx.updateTimelineShareData({
            title: document.title,  // 分享标题
            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: "https://res.10vows.com/static/10vows/favicon.ico"
          })
        })
      })
  }, [])

  function _loadMore(page) {
    const scrollTop = document.documentElement.scrollTop
    const clientHeight = document.documentElement.clientHeight
    const scrollHeight = document.documentElement.scrollHeight
    if (scrollHeight === clientHeight || !nextPage) return
    if (scrollHeight - scrollTop - clientHeight < 300) {
      setPage(nextPage)
      nextPage = 0
    }
  }

  function _updateArticles(data, lastPage) {
    if (page < lastPage) nextPage = page + 1
    if (page === 1) {
      setArticles(data)
    } else {
      setArticles(articles.concat(data))
    }
  }

  function fetchRecommends() {
    return http.post('/web/article/recommends', {page})
      .then(res => {
        setAds(res.ads)
        if(page === 1) {
          setTops(res.data.filter(x => x.is_top))
        }
        _updateArticles(res.data.filter(x => !x.is_top), res.last_page)
      })
  }

  function handleAd(url) {
    if (url) window.open(url)
  }

  function handleTypeChange(v) {
    setType(v)
    setPage(1)
  }

  return (
    <div className={css.mIndex}>
      <Header/>

      <div className={css.home}>
        <div className={css.types}>
          {
            types.map((t, i) =>
              <a key={t.c_alias} className={type.c_name === t.c_name ? css.cur : ''}
                 onClick={() => handleTypeChange(t)}>{t.c_name}</a>
            )
          }
        </div>

        <div className={css.listBox}>
          <div style={{width: '100%'}}>
            {type.c_alias === 'index_recommend' ? (
              <div className={css.fixedTop}>
                <Swiper spaceBetween={10} modules={[autoplay,pagination]} autoplay={params} pagination>
                  {ads.map((item, index) =>
                    <SwiperSlide key={index} className={css.picSlide}>
                      <div className={css.pic} style={{cursor: item.ad_url ? 'pointer' : 'auto'}}
                           onClick={() => handleAd(item.ad_url)}>
                        <img src={item.ad_cover} alt=""/>
                        <div className={css.picDesc}>{item.ad_title}</div>
                      </div>
                    </SwiperSlide>
                  )}
                </Swiper>

                {/*<div className={css.desc}>*/}
                {/*  {tops.map(item => (*/}
                {/*    <a key={item.a_id} href={`/detail/${item.a_id}`} target="_blank" rel="noreferrer">{item.a_title}</a>*/}
                {/*  ))}*/}
                {/*</div>*/}
                <div className={css.hotList}>
                  <List data={tops} type='top'/>
                </div>

              </div>
            ) : null}

            {
              type.c_alias === 'index_video'
                ? <ListVideo data={articles} />
                : (
                  type.c_alias === 'index_topic' ?  <ListTopic data={articles} /> : <List data={articles} />
                )
            }
            {fetching && <div className="textLoading">正在获取更多内容</div>}
          </div>
        </div>
      </div>

      <Footer/>
    </div>

  )
}
