import React, { useEffect, useState } from 'react';
import Info from "./Info";
import css from './styles.module.scss'
import { http } from "libs";

export default function (props) {
  const {id} = props.match.params
  const [loading, setLoading] = useState(true)
  const [record, setRecord] = useState([])

  useEffect(() => {
    setLoading(true)
    http.get('/web/share/wiki', {params: {b_id: id}})
      .then(res => setRecord(res)).finally(()=>setLoading(false))
  }, [id])

  return loading ? <div className={css.loadEffect}>
    <span/>
    <span/>
    <span/>
    <span/>
    <span/>
    <span/>
    <span/>
    <span/>
  </div> : <Info record={record}/>
}
