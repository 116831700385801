import React, { useState, useEffect } from "react";
import autoplay from 'swiper/modules/autoplay/autoplay.js';
import navigation from 'swiper/modules/navigation/navigation.js';
import { Swiper, } from 'swiper/react/swiper.js';// Import Swiper React components
import { SwiperSlide } from 'swiper/react/swiper-slide.js';// Import Swiper React components
import 'swiper/swiper.scss';// Import Swiper styles
import 'swiper/modules/autoplay/autoplay.scss';// Import Swiper styles
import 'swiper/modules/navigation/navigation.scss';// Import Swiper styles
import css from './styles.module.scss'

export default function (props) {
  const { record } = props

  const params = { enabled: false, pauseOnMouseEnter: true, disableOnInteraction: false }

  const [active, setActive] = useState({ active: 1, total: '', desc: '' })

  const [show, setShow] = useState(true)

  useEffect(() => {
    if (record.a_pic_content && record.a_pic_content.length > 0) {
      setActive({
        active: 1,
        total: record.a_pic_content.length,
        desc: record.a_pic_content[0].title || ''
      })
    }
  }, [record])
  
  const onSlideChange = item => {
    const rec = record.a_pic_content[item.activeIndex]
    setActive({
      active: item.activeIndex + 1,
      desc: rec.title || '',
      total: record.a_pic_content.length
    })
  }

  const onClick = (item, event) => {
    setShow(!show)
  }
  
  return (
    <div className={css.picNews}>
      <div style={{ position: 'fixed', top: 0, zIndex: 2, width: '100%' }}>
        <div className={css.topBox}>
          <div>
            <img src={record.avatar} className={css.avatar} />
            <div className={css.name}>{record.nickname}</div>
          </div>
          {
            show === false ? (
              <div className={css.nav}><span className={css.cur}>{active.active}</span>/{active.total}</div>
            ) : ''
          }
        </div>
      </div>
      <div className={css.box}>
        <Swiper
          spaceBetween={10}
          modules={[autoplay, navigation]}
          autoplay={params}
          navigation
          onSlideChange={(item) => onSlideChange(item)}
          onClick={(item, event) => onClick(item, event)}
        >
          {record.a_pic_content.map((item, index) =>
            <SwiperSlide key={index} className={css.picSlide}>
              <div className={css.pic1} >
                <img src={item.image} alt=""/>
              </div>
            </SwiperSlide>
          )}
        </Swiper>
        {
          show === true ? (
          <div className={css.bottom}>
            <div className={css.nav}><span className={css.cur}>{active.active}</span>/{active.total}</div>
              {active.active === 1 ? (
                <div className={css.atitle}>{record.a_title}</div>
              ) : '' }
            <div className={css.desc}>{active.desc}</div>
          </div>
          ) : ''
        }
      </div>
    </div>
  )
}