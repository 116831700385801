import React, { useEffect } from 'react';
import { human_timeStr, history } from "libs";
import css from './styles.module.scss'

export default function (props) {
  const {record} = props

  useEffect(() => {
    window.Aliplayer({
      id: 'video',
      width: '100%',
      height: '232px',
      cover: record.a_cover_url[0],
      source: record.a_video_url,
      skinLayout: [
        {name: "bigPlayButton", align: "blabs", x: 'calc(50% - 32px)', y: 'calc(50% - 32px)'},
        {
          name: "H5Loading", align: "cc"
        },
        {name: "errorDisplay", align: "tlabs", x: 0, y: 0},
        {name: "infoDisplay"},
        {name: "tooltip", align: "blabs", x: 0, y: 56},
        {name: "thumbnail"},
        {
          name: "controlBar", align: "blabs", x: 0, y: 0,
          children: [
            {name: "progress", align: "blabs", x: 0, y: 44},
            {name: "playButton", align: "tl", x: 15, y: 12},
            {name: "timeDisplay", align: "tl", x: 10, y: 7},
            {name: "fullScreenButton", align: "tr", x: 10, y: 12},
            {name: "setting", align: "tr", x: 15, y: 12},
            {name: "volume", align: "tr", x: 5, y: 10}
          ]
        }
      ]
    })
  }, [])

  return (
      <div className={css.videoBox}>
        <div className={css.video}>
          <video id="video"/>
        </div>

        <div className={css.inner}>

        <div className={css.name}>{record.a_title}</div>
        <div className={css.info}>{human_timeStr(record.a_create_time * 1000)}发布 {record.a_read_count}次播放</div>


        <div className={css.list}>
          {
            record.video_recommends && record.video_recommends.map((v, i) => (
              <div key={i} className={css.item} onClick={() => history.push(`/m/news/${v.a_id}`)}>
                <div className={css.l}>
                  <div className={css.t1}>{v.a_title}</div>
                  <div className={css.t2}>{v.a_comment_count}评论 {v.a_read_count}次播放</div>
                </div>
                <div className={css.pic}>
                  <img src={v.a_cover_url[0]} alt=""/>
                  {
                    record.a_type === 2 ?
                      <div className={css.time}>{v.a_video_duration}</div> : null
                  }
                </div>
              </div>
            ))
          }
        </div>
      </div>

    </div>
  )
}
