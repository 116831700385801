import homeIndex from './pages/home';
import aboutIndex from './pages/about/index';
import detailIndex from './pages/detail/index';
import detailVideo from './pages/detail/video';
import downloadIndex from './pages/download/index';
import searchIndex from './pages/search/index';
import adIndex from './pages/ad/index';
import feedbackIndex from './pages/feedback/index';
import supportIndex from './pages/support/index';
import TopicDetail from './pages/topic/index';
import AtlasDetail from './pages/atlas/index'

const routes = [
  {title: '首页', path: '/', component: homeIndex},
  {title: '分类页', path: '/cate/:type', component: homeIndex},
  {title: '文章详情', path: '/detail/:id', component: detailIndex},
  {title: '视频详情', path: '/detail_video/:id', component: detailVideo},
  {title: '图集详情', path: '/detail_atlas/:id', component: AtlasDetail},
  {title: '关于我们', path: '/about', component: aboutIndex},
  {title: '广告合作', path: '/ad', component: adIndex},
  {title: '意见反馈', path: '/feedback', component: feedbackIndex},
  {title: '合作支持', path: '/support', component: supportIndex},
  {title: '下载', path: '/download', component: downloadIndex},
  { title: '搜索', path: '/search', component: searchIndex },
  { title: '专题详情', path: '/topic/:id', component: TopicDetail }
]

export default routes
