import React from 'react';
import css from './index.module.scss'
import icd from "./pic/ic_d.png";
import iczs from "./pic/ic_zs2.png";
import more from "./pic/more.png";

export default function List1(props) {

  function getDetailUrl(item) {
    const urlCfg = {
      1: `/detail/${item.a_id}`,
      2: `/detail_video/${item.a_id}`,
      3: `/detail_atlas/${item.a_id}`
    }
    return urlCfg[item.a_type]
  }

  return <>
    <div className={css.listHd}>
      <img className={css.pic1} src={icd} alt=""/>
      <span className={css.tit}>推荐</span>
      <img className={css.pic2} src={iczs} alt=""/>
      <span className='flex1'/>
      {/*<img className={css.more} src={more} alt=""/>*/}
    </div>

    <div className={css.list1}>
      <div className={css.line}/>
      {props.data.map(item => (
        <div key={item.a_id} className={css.item}>
          <div className={css.info}>
            <a href={getDetailUrl(item)} className={css.title}
               target="_blank" rel="noreferrer">{item.a_title}</a>
            <div className={css.desc}>{item.a_create_time_str} {item.ag_name}</div>
          </div>
        </div>
      ))}
    </div>
  </>
}
