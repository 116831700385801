import React, { useEffect, useState } from 'react';
import List from "./List";
import css from './styles.module.scss'
import logo from './logo.png'
import { http } from "libs";

export default function (props) {
  const {id} = props.match.params
  const [loading, setLoading] = useState(true)
  const [record, setRecord] = useState([])

  useEffect(() => {
    setLoading(true)
    http.get('/web/share/topic', {params: {t_id: id}})
      .then(res => setRecord(res)).finally(() => setLoading(false))
    // s.fetchRecords(id).then(() => setLoading(false))
  }, [id])


  //int	文章类型。1:图文：2:视频。
  return (
      <>
        {
          loading ? <div className={css.loadEffect}>
            <span/>
            <span/>
            <span/>
            <span/>
            <span/>
            <span/>
            <span/>
            <span/>
          </div> : <>
            <List record={record}/>

            {/*<div className={css.popTip}>*/}
            {/*  <a href="/" className={css.logo}><img src={logo} alt=""/></a>*/}
            {/*  <a href="/" className={css.btnRed}>立即打开</a>*/}
            {/*</div>*/}
          </>
        }

      </>
  )
}
