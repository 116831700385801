import React from 'react';
import css from './index.module.scss'

export default function ListTopic(props) {
  function renderUrl(item) {
    return `/topic/${item.t_id}`
  }
  return (
    <div className={css.listTopic}>
      {props.data.map(item => (
        <a className={css.item} key={item.t_id} href={renderUrl(item)} target="_blank" rel="noreferrer">
          <div className={ css.cover }>
            <img src={item.t_list_cover} alt={item.t_title} />
          </div>
          <div className={css.desc}>
            <div className={css.title}>{item.t_title}</div>
            <div className={css.subBox}>{ item.t_desc }</div>
          </div>
        </a>
      ))}
    </div>
  )
}
