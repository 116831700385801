import React, { useState, useEffect, useRef } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import routes from '../routes';
import { hasPermission, isMobile, history } from 'libs';
import styles from './layout.module.scss';

function initRoutes(Routes, routes) {
  for (let route of routes) {
    if (route.component) {
      if (!route.auth || hasPermission(route.auth)) {
        Routes.push(<Route exact key={route.path} path={route.path} component={route.component}/>)
      }
    } else if (route.child) {
      initRoutes(Routes, route.child)
    }
  }
}

// 404
function NotFound() {
  return (
    <div className={styles.router}>
      <div className={styles.imgBlock}>
        <div className={styles.img}/>
      </div>
      <div>
        <h1 className={styles.title}>404</h1>
        <div className={styles.desc}>抱歉，你访问的页面不存在</div>
      </div>
    </div>
  )
}

function LayoutIndex() {
  const ref = useRef()
  const [ready, setReady] = useState(false)
  const [Routes, setRoutes] = useState([]);
  const {pathname} = useLocation();  // 存储当前路由地址`

  useEffect(() => {
    const Routes = [];
    initRoutes(Routes, routes);
    setRoutes(Routes);
    setReady(true)
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div ref={ref} className={styles.layoutBox} onScroll={e => console.log(e)}>
      <Header hiddenSearch={pathname.includes('/search')}/>
      <Switch>
        {Routes}
        <Route component={ready ? NotFound : null}/>
      </Switch>
      <Footer/>
    </div>
  )
}

export default LayoutIndex
