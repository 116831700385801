import React, { useEffect, useState } from 'react';
import autoplay from 'swiper/modules/autoplay/autoplay.js';
import navigation from 'swiper/modules/navigation/navigation.js';
import { Swiper, } from 'swiper/react/swiper.js';// Import Swiper React components
import { SwiperSlide } from 'swiper/react/swiper-slide.js';// Import Swiper React components
import { http } from 'libs';
import Top from './Top'
import List from './List'
import ListVideo from './ListVideo'
import List1 from './List1'
import List2 from './List2'
import ListTopic from './ListTopic'
import css from './index.module.scss'
import 'swiper/swiper.scss';// Import Swiper styles
import 'swiper/modules/autoplay/autoplay.scss';// Import Swiper styles
import 'swiper/modules/navigation/navigation.scss';// Import Swiper styles

let nextPage = 1

export default function (props) {
  const defaultTypes = [
    {"c_alias": "index_recommend", "c_name": "首页"},
    {"c_alias": "news", "c_name": "资讯"},
    {"c_alias": "index_article_guoxue", "c_name": "国学"},
    {"c_alias": "index_article_cultural_tourism", "c_name": "文旅"},
    {"c_alias": "index_article_read_book", "c_name": "读书"},
    {"c_alias": "index_article_knowledge_library", "c_name": "知库"},
    { "c_alias": "index_video", "c_name": "视频" },
    {"c_alias": "index_topic", "c_name": "专题"}
  ]
  const c_alias = props.match.params.type
  const [type, setType] = useState(c_alias ? {c_alias} : {c_alias: 'index_recommend'})
  const [types, setTypes] = useState(defaultTypes)
  const [ads, setAds] = useState([])
  const [tops, setTops] = useState([])
  const [banner, setBanner] = useState({})
  const [banners, setBanners] = useState([])
  const [latest, setLatest] = useState([])
  const [articles, setArticles] = useState([])
  const [download, setDownload] = useState({})
  const [videRecommends, setVideoRecommends] = useState([])
  const [fetching, setFetching] = useState(true)
  const [page, setPage] = useState(1)
  const params = {enabled: true, delay: 3000, pauseOnMouseEnter: true, disableOnInteraction: false}

  useEffect(() => {
    http.get('/web/index/category')
      .then(res => setTypes(res.data))
    http.post('/web/index/right_info')
      .then(res => {
        setLatest(res.latest)
        setBanner(res.banner)
        setDownload(res.download)
        setVideoRecommends(res.video_recommends)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setFetching(true)
    if (type.c_alias === 'index_recommend') {
      fetchRecommends().finally(() => setFetching(false))
    } else if (type.c_alias === 'index_video') {
      http.post('/web/article/videos', {page})
        .then(res => _updateArticles(res.data, res.last_page))
        .finally(() => setFetching(false))
    } else if (type.c_alias === 'index_topic') {
      http.post('/web/topic/lists', { page })
        .then(res => _updateArticles(res.data, res.last_page))
        .finally(() => setFetching(false))
    } else {
      http.post('/web/article/lists', {page, c_alias: type.c_alias})
        .then(res => _updateArticles(res.data, res.last_page))
        .finally(() => setFetching(false))
    }

    if(type.c_alias === 'index_recommend'){
      document.title= `十愿网`
      // document.getElementsByName('keywords')[0].content = `十愿网`
      // document.getElementsByName('description')[0].content = `十愿网`
    } else {
      let typeName = types.filter(v => v.c_alias === c_alias)[0].c_name
      document.title= `${typeName}_十愿网`
      // document.getElementsByName('keywords')[0].content = `${typeName}, 十愿网`
      // document.getElementsByName('description')[0].content = `${typeName}`
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, type])

  // useEffect(() => {
  //   window.addEventListener('scroll', _loadMore)
  //   return () => {
  //     window.removeEventListener('scroll', _loadMore)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])
  //
  // function _loadMore(page) {
  //   const scrollTop = document.documentElement.scrollTop
  //   const clientHeight = document.documentElement.clientHeight
  //   const scrollHeight = document.documentElement.scrollHeight
  //   if (scrollHeight === clientHeight || !nextPage) return
  //   if (scrollHeight - scrollTop - clientHeight < 300) {
  //     setPage(nextPage)
  //     nextPage = 0
  //   }
  // }

  function _updateArticles(data, lastPage) {
    if (page < lastPage) nextPage = page + 1
    if (page === 1) {
      setArticles(data)
    } else {
      setArticles(articles.concat(data))
    }
  }

  function fetchRecommends() {
    return http.post('/web/article/recommends', {page})
      .then(res => {
        setAds(res.ads)
        setBanners(res.tops)
        setTops(res.data.filter(x => x.is_top))
        _updateArticles(res.data.filter(x => !x.is_top), res.last_page)
      })
  }

  function getDetailUrl(item) {
    const urlCfg = {
      1: `/detail/${item.a_id}`,
      2: `/detail_video/${item.a_id}`,
      3: `/detail_atlas/${item.a_id}`
    }
    return urlCfg[item.a_type]
  }

  return (
    <div className={css.home} onScrollCapture={e => console.log(e)}>

      <div className={css.banner}>
        <img src={banner.ad_cover} alt={banner.ad_title}/>
      </div>

      {type.c_alias === 'index_recommend' && <Top data={banners}/>}

      <div className={css.types}>
        {
          types.map((t, i) =>
            <a key={i}
               rel="noreferrer"
               className={type.c_alias === t.c_alias ? css.cur : ''}
               href={t.c_alias === 'index_recommend' ? '/' : `/cate/${t.c_alias}`}
               target="_blank"
            >{t.c_name}</a>
          )
        }
      </div>

      <div className={css.listBox}>
        <div className={css.leftList}>
          {type.c_alias === 'index_recommend' && (
            <div className={css.fixedTop}>
              <Swiper spaceBetween={10} modules={[autoplay, navigation]} autoplay={params} navigation>
                {ads.map((item, index) =>
                  <SwiperSlide key={index} className={css.picSlide}>
                    <a className={css.pic}
                       rel="noreferrer"
                       style={{cursor: item.ad_url ? 'pointer' : 'auto'}}
                       href={item.ad_url}
                       target="_blank"
                    >
                      <img src={item.ad_cover} alt=""/>
                      <div className={css.picDesc}>{item.ad_title}</div>
                    </a>
                  </SwiperSlide>
                )}
              </Swiper>

              <div className={css.topList}>
                {tops.map(item => (
                  <div key={item.a_id} className={css.item}>
                    <a href={getDetailUrl(item)}
                       target="_blank" rel="noreferrer">{item.a_title}</a>
                  </div>
                ))}
              </div>
              <div className={css.spaceLine}/>
            </div>
          )}
          {
            type.c_alias === 'index_video'
              ? <ListVideo data={articles} />
              : (
                type.c_alias === 'index_topic' ?  <ListTopic data={articles} /> : <List data={articles} />
              )
          }
          {fetching ? (
            <div className="textLoading">正在获取更多内容</div>
          ) :
              (
                type.c_alias === 'index_topic'
                ? (
                  <div className={ css.loadMore } onClick={() => setPage(nextPage)}>加载更多<span></span></div>
                  )
                  : <div className="textLoading" onClick={() => setPage(nextPage)}>点击加载更多内容</div>
              )
            
          }
        </div>
        <div className={css.rightList}>
          <List1 data={latest}/>
          <List2 data={videRecommends}/>

          <div onClick={() => download.ad_url && window.open(download.ad_url)} className={css.download}
               style={download.ad_url ? {cursor: 'pointer'} : {}}>
            <img src={download.ad_cover} alt=""/>
          </div>
        </div>
      </div>
    </div>
  )
}
