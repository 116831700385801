import React from 'react';
import { history } from "libs";
import css from './index.module.scss'
import play1 from './pic/play1.png';

export default function List(props) {
  function handleClick(item) {
    const urlCfg = {
      1: `/m/news/${item.a_id}`,
      2: `/m/news/${item.a_id}`,
      3: `/m/news/${item.a_id}`,
    }
    history.replace(urlCfg[item.a_type])
  }
  //文章标题样式。0:无图；1:左文右图；2:三图；3:大图；4:视频
  return (
    <div className={css.list}>
      {props.data.map(item => (
        <div className={css.item} key={item.a_id} onClick={() => handleClick(item)}>
          {props.type === 'top' ? (
            <div className={css.info}>
              <a className={css.ttitle}>{item.a_title}</a>
              <div className={css.desc} style={{marginTop: 18}}>
                <span className={css.hot}>热门</span>
                {item.nickname}
                <span className={css.dot}/>
                {item.a_read_count}阅读
                {/*<span className={css.dot}/>*/}
                {/*{item.a_comment_count}评论*/}
                {/*<span className='flex1'/>*/}
                {/*{item.a_create_time_format}*/}
              </div>
            </div>
          ) : [1, 4].includes(item.a_title_style) ? (
            <>
              <div className={css.info}>
                <a className={css.title}>{item.a_title}</a>
                <div className={css.desc}>
                  {item.nickname}
                  <span className={css.dot}/>
                  {item.a_read_count}阅读
                </div>
              </div>

              <div className={css.pic}>
                <img src={item.a_cover_url[0]} alt=""/>
                {item.a_title_style === 4 && <div className={css.play}><img src={play1} alt=""/></div>}
              </div>
            </>
          ) : item.a_title_style === 2 ? (
            <div className={css.info}>
              <a className={css.title}>{item.a_title}</a>
              <div className={css.pics}>
                {item.a_cover_url.slice(0, 3).map((item, index) => (
                  <img key={index} src={item} alt=""/>
                ))}
              </div>
              <div className={css.desc}>
                {item.nickname}
                <span className={css.dot}/>
                {item.a_read_count}阅读
              </div>
            </div>
          ) :item.a_title_style === 3 ? (
            <div className={css.info}>
              <a className={css.title}>{item.a_title}</a>
              <div className={css.picBig}>
                <img src={item.a_cover_url[0]} alt=""/>
              </div>
              <div className={css.desc}>
                {item.nickname}
                <span className={css.dot}/>
                {item.a_read_count}阅读
              </div>
            </div>
          ) : (
            <div className={css.info}>
              <a className={css.title}>{item.a_title}</a>
              <div className={css.pics}/>
              <div className={css.desc}>
                {item.nickname}
                <span className={css.dot}/>
                {item.a_read_count}阅读
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}
