import React, { useEffect, useState } from 'react';
import { http, history } from 'libs';
import List1 from '../home/List1'
import List2 from '../home/List2'
import css from './index.module.scss'
import wx from './pic/wx.png'
import qzone from './pic/qzone.png'
import xz from './pic/bg_xz.png'
import icd from "./pic/ic_d.png";
import iczs from "./pic/ic_zs2.png";
import moment from 'moment';
import QRCode from 'qrcode.react';
import "./index.css";

export default function (props) {
  const {id} = props.match.params
  const [latest, setLatest] = useState([])
  const [article, setArticle] = useState({comments: []})
  const [downloadURL, setDownloadURL] = useState()
  const [videRecommends, setVideoRecommends] = useState([])

  useEffect(() => {
    http.get('/web/share/article', {params: {a_id: id}})
      .then(res => {
        setArticle(res)

        document.title= `${res.a_title}_十愿网`
        // document.getElementsByName('keywords')[0].content = `${res.a_title}, 十愿网`
        // document.getElementsByName('description')[0].content = `${res.a_title}`
      })
    http.post('/web/index/right_info')
      .then(res => {
        setLatest(res.latest)
        setDownloadURL(res.app_download_page)
        setVideoRecommends(res.video_recommends)
      })
  }, [id])

  function renderQrcode() {
    return <div className={css.qrcodeBox}>
      <div className={css.qrcode}>
        <QRCode
          value={window.location.origin + '/m/news/' + id}
          size={80} // 二维码的大小
          fgColor="#000000" // 二维码的颜色
          style={{margin: 'auto'}}
        />
      </div>
      <p>微信扫码分享</p>
    </div>
  }

  function gotoHome() {
    history.push(`/cate/${article.c_alias}`)
  }

  return (
    <div className={css.home}>
      {
        article.c_name ? <div className={css.breadcrumb}><a onClick={gotoHome}>{article.c_name}</a> > 正文</div> : ''
      }

      <div className={css.listBox}>
        <div className={css.leftList}>

          <div className={css.article}>
            <div className={css.title}>{article.a_title}</div>
            <div className={css.info}>
              <div className={css.l}>
                <div>{moment(article.a_create_time * 1000).format('YYYY年MM月DD日 HH:mm:ss')}</div>
                <div>{article.nickname}</div>
              </div>
              <a className={css.r} target='_blank' title='分享到QQ空间'
                 href={`https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=https://www.10vows.com/detail/${id}&title=${article.share_title}&pics=${article.share_img}`} rel="noreferrer">
                <img src={qzone} alt=""/>
              </a>
              <div className={css.r}>
                <img src={wx} alt=""/>
                {renderQrcode()}
              </div>
            </div>

            <div className={css.detail}>
              <div dangerouslySetInnerHTML={{__html: article.a_content}}/>
            </div>

          </div>

          <div className={css.comments}>
            <div className={css.listHd}>
              <img className={css.pic1} src={icd} alt=""/>
              <span className={css.tit}>全部评论</span>
              <img className={css.pic2} src={iczs} alt=""/>
            </div>

            <div className={css.commentsList}>
              {article.comments.map(item => (<div key={item.ac_id} className={css.item}>
                <div className={css.hd}>
                  <img src={item.avatar} alt=""/>
                  <div className={css.t}>
                    <span className={css.userName}>{item.nickname}</span>
                    <span className={css.time}>· {item.ac_create_time_str}</span>
                  </div>
                </div>
                <div className={css.detail}>
                  <div className={css.comment}>{item.ac_content}</div>
                </div>
              </div>))}
              {article.a_id && article.comments.length === 0 && (<div className={css.nodata}>暂无评论</div>)}
            </div>
          </div>
          
        </div>
        <div className={css.rightList}>
          <List1 data={latest}/>
          <List2 data={videRecommends}/>

          <a href={downloadURL} className={css.download} target="_blank" rel="noreferrer">
            <img src={xz} alt=""/>
          </a>
        </div>
      </div>
    </div>)
}
