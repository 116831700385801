import React, { useEffect, useState } from "react";
import { http } from 'libs';
import css from './index.module.scss'
import List from './List'
import Comments from "./Comments";
import { Link, Element } from 'react-scroll'

export default function TopicIndex(props) {

  const {id} = props.match.params
  const [topic, setTopic] = useState({})
  const [active, setActive] = useState(0)

  useEffect(() => {
    const params = { t_id: id }
    if (window.location.search.indexOf('token') > -1) {
      const str = window.location.search.substring(window.location.search.indexOf('?') + 1)
      const token = str.split('=')[1] || ''
      if (token) {
        params.token = token
      }
    }
    http.post('/web/topic/details', params)
      .then(res => {
        const first = res.categories[0]
        if (first) {
          setActive(first.tc_id)
        }
        setTopic(res)
      })
  }, [id])

  const onCategoryClick = id => {
    setActive(id)
    // scrollToAnchor(id)
  }

  const scrollToAnchor = (anchorName) => {
    if (anchorName) {
      const anchorElement = document.getElementById(anchorName)
      if(anchorElement) { anchorElement.scrollIntoView({block: 'start', behavior: 'smooth'}) }
    }
  }

  return (
    <div className={css.container}>
      <img className={css.banner} src={topic.t_cover} alt={topic.t_title}></img>
      <div className={css.containerBox}>
        {/* <div className={css.title}>{topic.t_title}</div>
        <div className={css.sub}>{topic.t_desc}</div> */}
        <div className={css.nav}>
          <ul>
            {(topic.categories || []).map(item => {
              return (
                <Link
                  key={item.tc_id}
                  activeClass={css.active}
                  to={ 'c_' + item.tc_id }
                  spy={true}
                  smooth={true}
                  duration={250}
                >
                  <span>{item.tc_title}</span>
                </Link>
              )
            })}
          </ul>
        </div>
        {(topic.categories || []).map(item => 
          <Element name={'c_' + item.tc_id} className="element">
            <div className={css.ctitle}>{item.tc_title}</div>
            <List data={ item.articles } />
          </Element>
        )}
        <Comments tid={ id } />
      </div>
    </div>
  )

}