import React, { useState, useEffect } from "react";
import autoplay from 'swiper/modules/autoplay/autoplay.js';
import navigation from 'swiper/modules/navigation/navigation.js';
import { Swiper, } from 'swiper/react/swiper.js';// Import Swiper React components
import { SwiperSlide } from 'swiper/react/swiper-slide.js';// Import Swiper React components
import {http, history} from "libs";
import css from './index.module.scss';
import moment from "moment";
import 'swiper/swiper.scss';// Import Swiper styles
import 'swiper/modules/autoplay/autoplay.scss';// Import Swiper styles
import 'swiper/modules/navigation/navigation.scss';// Import Swiper styles
import QRCode from 'qrcode.react';
import wx from './pic/wx.png'
import qzone from './pic/qzone.png'

export default function (props) {

  const {id} = props.match.params
  const [banner, setBanner] = useState({})
  const [article, setArticle] = useState({ comments: [] })
  const [images, setImages] = useState([])

  const [active, setActive] = useState({active: 1, total: '', desc: ''})
  
  const params = {enabled: false, pauseOnMouseEnter: true, disableOnInteraction: false}

  useEffect(() => {

    http.get('/web/share/article', {params: {a_id: id}})
      .then(res => {
        setArticle(res)
        setImages(res.a_pic_content || [])
        if (res.a_pic_content && res.a_pic_content.length > 0) {
          setActive({
            active: 1,
            total: res.a_pic_content.length,
            desc: res.a_pic_content[0].title || ''
          })
        }
        document.title= `${res.a_title}_十愿网`
      })

    http.post('/web/index/right_info')
    .then(res => {
      setBanner(res.banner)
    })
  }, [id])

  const goHome = () => history.push(`/cate/${article.c_alias}`)
  const goBack = () => history.goBack()

  const onSlideChange = item => {
    const rec = images[item.activeIndex]
    setActive({
      active: item.activeIndex + 1,
      desc: rec.title || '',
      total: images.length
    })
  }

  function renderQrcode() {
    return <div className={css.qrcodeBox}>
      <div className={css.qrcode}>
        <QRCode
          value={window.location.origin + '/m/news/' + id}
          size={80} // 二维码的大小
          fgColor="#000000" // 二维码的颜色
          style={{margin: 'auto'}}
        />
      </div>
      <p>微信扫码分享</p>
    </div>
  }
  
  return (
    <div className={css.home}>
       <div className={css.banner}>
        <img src={banner.ad_cover} alt={banner.ad_title}/>
      </div>
      <div className={css.container}>
        {
          article.c_name ? <div className={css.breadcrumb}><a onClick={goHome}>{article.c_name}</a> > 正文</div> : ''
        }
        <div className={css.title}>{article.a_title}</div>
        <div className={css.info}>
          <div className={css.l}>
            <div>{moment.unix(article.a_release_time).format('YYYY-MM-DD HH:mm')}</div>
            <div>{article.nickname}</div>
          </div>
          <a className={css.r} target='_blank' title='分享到QQ空间'
              href={`https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=https://www.10vows.com/detail/${id}&title=${article.share_title}&pics=${article.share_img}`} rel="noreferrer">
            <img src={qzone} alt=""/>
          </a>
          <div className={css.r}>
            <img src={wx} alt=""/>
            {renderQrcode()}
          </div>
        </div>
        <div className={css.box}>
          <Swiper
            spaceBetween={10}
            modules={[autoplay, navigation]}
            autoplay={params}
            navigation
            onSlideChange={(item) => onSlideChange(item)}
          >
            {images.map((item, index) =>
              <SwiperSlide key={index} className={css.picSlide}>
                <div className={css.pic}>
                  <img src={item.image} alt=""/>
                </div>
              </SwiperSlide>
            )}
          </Swiper>
          <div className={css.bottom}>
            <div className={css.nav}><span className={css.cur}>{active.active}</span>/{active.total}</div>
            {/* <div className={css.line}></div> */}
            <div className={css.desc}>{active.desc}</div>
          </div>
        </div>
        <div className={css.commentsTitle}>热门评论</div>
        <div className={css.comments}>
            {/* <div className={css.listHd}>
              <img className={css.pic1} src={icd} alt=""/>
              <span className={css.tit}>全部评论</span>
              <img className={css.pic2} src={iczs} alt=""/>
            </div> */}

            <div className={css.commentsList}>
              {article.comments.map(item => (<div key={item.ac_id} className={css.item}>
                <div className={css.hd}>
                  <img src={item.avatar} alt=""/>
                  <div className={css.t}>
                    <span className={css.userName}>{item.nickname}</span>
                    <span className={css.time}>· {item.ac_create_time_str}</span>
                  </div>
                </div>
                <div className={css.detail}>
                  <div className={css.comment}>{item.ac_content}</div>
                </div>
              </div>))}
              {article.a_id && article.comments.length === 0 && (<div className={css.nodata}>暂无评论</div>)}
            </div>
          </div>
      </div>
    </div>
  )
}