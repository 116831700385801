
import React, { useEffect, useState } from 'react';
import { http } from 'libs';
import css from './index.module.scss'
import List from './List'
import List1 from '../home/List1'
import Comments from './Comments';
import { Link, Element } from 'react-scroll'

export default function Index(props) {

  const { id } = props.match.params

  const [topic, setTopic] = useState({})
  const [active, setActive] = useState(0)
  const [download, setDownload] = useState({})
  const [latest, setLatest] = useState([])

  useEffect(() => {
    http.post('/web/index/right_info')
      .then(res => {
        setLatest(res.latest)
        setDownload(res.download)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const params = { t_id: id }
    if (window.location.search.indexOf('token') > -1) {
      const str = window.location.search.substring(window.location.search.indexOf('?') + 1)
      const token = str.split('=')[1] || ''
      if (token) {
        params.token = token
      }
    }
    http.post('/web/topic/details', params)
      .then(res => {
        const first = res.categories[0]
        if (first) {
          setActive(first.tc_id)
        }
        setTopic(res)
      })
  }, [id])

  const onCategoryClick = id => {
    setActive(id)
  }

  return (
    <div className={css.container}>
      <div className={css.banner}>
        <img src={topic.t_pc_cover} alt={topic.t_title} />
        <div className={css.bannberMask}></div>
        <div className={css.bannerDesc}>
          <h2>{topic.t_pc_cover_text ? topic.t_title : ''}</h2>
          <p>{ topic.t_pc_cover_text ? topic.t_sub_title : ''}</p>
        </div>
      </div>
      <div className={css.contentBox}>
        <ul className={css.categoryBox}>
          {(topic.categories || []).map(item => {
            return (
              <Link
                key={item.tc_id}
                activeClass={css.active}
                to={ 'c_' + item.tc_id }
                spy={true}
                smooth={true}
                duration={250}
              >
                <span>{item.tc_title}</span>
              </Link>
            )
          })}
        </ul>
        <div className={css.leftList}>
          {/* <div className={css.ltitle}><a href="/cate/index_topic">专题</a>  >  详情</div> */}
          {(topic.categories || []).map(item => 
            <Element name={'c_' + item.tc_id} className="element">
              <div className={css.ctitle}>{item.tc_title}</div>
              <List data={ item.articles } />
            </Element>
          )}
          <div className={css.commentsTitle}>热门评论</div>
          <Comments tid={ id } />
          <div className={ css.commentBox }>
            <textarea placeholder="输入你的评论" />
            <div style={{ textAlign: 'right', width: '100%' }}>
              <div className={ css.commentBtn }>发布评论</div>
            </div>
          </div>

        </div>
        <div className={css.rightList}>
          <List1 data={ latest } />
          <div onClick={() => download.ad_url && window.open(download.ad_url)} className={css.download}
               style={download.ad_url ? {cursor: 'pointer'} : {}}>
            <img src={download.ad_cover} alt=""/>
          </div>
        </div>
      </div>
    </div>
  )
}