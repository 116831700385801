import React, { useEffect, useState } from 'react';
import { http } from 'libs';
import QRCode from 'qrcode.react';
import css from './styles.module.scss'
import pic1 from './pic/pic_1.png'
import logo from './pic/logo.png'
import androidLogo from './pic/android.png'
import iosLogo from './pic/ios.png'

export default function () {
  const [data, setData] = useState({})

  useEffect(() => {
    document.title = `十愿网`
    // document.getElementsByName('keywords')[0].content = `十愿网`
    // document.getElementsByName('description')[0].content = `十愿网`

    http.post('/web/index/download')
      .then(res => setData(res))
  }, [])
  return (
    <div className={css.box}>
      <img className={css.pic1} src={pic1} alt=""/>
      <div className={css.links}>
        <img className={css.logo} src={logo} alt=""/>
        <div className={css.btns}>
          <a className={css.btn2} href='https://apps.apple.com/app/id1596314940'><img src={iosLogo} alt=""/>iOS版</a>
          <a href={data.android_download_link} className={css.btn1}><img src={androidLogo} alt=""/>Android版</a>
          <span className={css.code}>
            {data.app_download_page && <QRCode size={94} includeMargin value={data.app_download_page}/>}
          </span>
        </div>
      </div>
    </div>
  )
}

