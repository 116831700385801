import React, {useEffect} from 'react';
import css from './index.module.scss'
import bg from "../about/bg.png";

export default function Index() {
  useEffect(() => {
    document.title = `广告合作_十愿网`
    // document.getElementsByName('keywords')[0].content = `广告合作, 十愿网`
    // document.getElementsByName('description')[0].content = `广告合作`
  }, [])

  return (
    <React.Fragment>
      <div className={css.aboutPage}>
        <img className={css.banner} src={bg} alt=""/>
        <div className={css.about}>
          <div className={css.title}>广告合作</div>
          <div className={css.contact}>
            <div className={css.tit}>联系方式</div>
            <ul>
              <li>QQ/微信：</li>
              <li>邮箱：<a href="mailto: kefu@10vows.com">kefu@10vows.com</a></li>
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
