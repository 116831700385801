import React from 'react';
import { checkTime, history, human_timeStr } from 'libs'
import css from './styles.module.scss'

export default function (props) {
  const {record} = props

  function renderDuration(duration) {
    let m = Math.floor(duration / 60)
    let sec = Math.ceil(duration % 60)
    return checkTime(m) + ':' + checkTime(sec)
  }

  return (
    <div>
      <div className={css.ztInfo}>
        <img src={record.t_cover} alt=""/>
        <div className={css.info}>
          <div className={css.title}>{record.t_title}</div>
          <div className={css.desc}>{record.t_desc}</div>
        </div>
      </div>
      <div className={css.lists}>
        {
          record.categories ? record.categories.map((cat, index) => (
            <div key={index} className={css.list}>
              <div className={css.tc_title}>{cat.tc_title}</div>
              {
                cat.articles.map((v, i) => (
                  <div key={i} className={css.item} onClick={() => history.push(`/m/news/${v.a_id}`)}>
                    <div className={css.l}>
                      <div className={css.t1}>{v.a_title}</div>
                      <div className={css.t2}>{v.nickname} · {human_timeStr(v.a_create_time * 1000)}</div>
                    </div>
                    {v.a_cover_url.length > 0 && (
                      <div className={css.pic}>
                        <img src={v.a_cover_url[0]} alt=""/>
                        {
                          v.a_type === 2 && v.a_video_duration ?
                            <div className={css.time}>{v.a_video_duration}</div> : null
                        }
                      </div>
                    )}
                  </div>
                ))
              }
            </div>
          )) : <div className={css.nodata}>
            404 /(ㄒoㄒ)/~~
            <br/>
            <br/>
            专题已下架或已删除~~
          </div>
        }

      </div>
    </div>

  )
}
