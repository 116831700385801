import React from 'react';
import { history } from "libs";
import css from './index.module.scss'

export default function ListTopic(props) {
  function handleClick(t_id) {
    history.push(`/m/topic/${t_id}`)
  }
  return (
    <div className={css.listTopic}>
      {props.data.map(item => (
        <div className={css.item} key={item.t_id} onClick={() => handleClick(item.t_id)}>
          <div className={css.titleBox}>
            <div className={css.topicIcon}>专题</div>
            <div className={css.topicTitle}>{item.t_title}</div>
          </div>
          <div className={css.coverBox}>
            <img src={item.t_list_cover} alt={item.t_title} />
          </div>
          <div className={css.bottomBox}>
            <div className={css.time}>{item.t_create_time_str}</div>
            <div>进入专题》</div>
          </div>
        </div>
      ))}
    </div>
  )
}
