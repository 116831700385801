import React, { useEffect, useState } from 'react';
import { history } from "libs";
import css from './layout.module.scss';
import logo from './logo.png'
import searchPic from './search.png'

export default function Header(props) {
  const [shadow, setShadow] = useState(false)
  useEffect(() => {
    window.addEventListener('scroll', showShadow)
    return () => {
      window.removeEventListener('scroll', showShadow)
    }
  }, [])

  function showShadow() {
    let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
    setShadow(!!scrollTop)
  }

  return (
    <div className={[css.header, shadow ? css.shadow : ''].join(' ')}>
      <div className={css.mainBox}>
        <div className={css.logo}>
          <span className='link' onClick={() => history.push('/')}><img src={logo} alt=""/></span>
        </div>
        <div className="flex1"/>
        {
          props.hiddenSearch ? null :
            <form action='/search' className={css.search} target="_blank">
              <input name='keyword' type="text" placeholder='请输入搜索内容'/>
              <button type='submit' className={css.btn}>
                <img src={searchPic} alt=""/>
              </button>
            </form>
        }
        <a href="https://mp.10vows.com" target="_blank" className={css.publish}>发布作品</a>
      </div>
    </div>
  )
}
