import React from 'react';
import css from './layout.module.scss';
import code from './code.png'
import code2 from './code2.png'

export default function Footer() {
  return (
    <div className={css.footer}>
      <div className={css.inner}>
        <div className={css.l}>
          <div className={css.links}>
            <a href="/about" target="_blank" rel="noreferrer">关于我们</a>
            <span className={css.split}/>
            <a href="/ad" target="_blank" rel="noreferrer">广告合作</a>
            <span className={css.split}/>
            <a href="/feedback" target="_blank" rel="noreferrer">意见反馈</a>
            <span className={css.split}/>
            <a href="/support" target="_blank" rel="noreferrer">合作支持</a>
            <span className={css.split}/>
            {/*<a href="/download" target="_blank" rel="noreferrer">APP下载</a>*/}
            {/*<span className={css.split}/>*/}
            <a href="https://www.10vows.com/web/agreement/privacy" target="_blank" rel="noreferrer">隐私协议</a>
            <span className={css.split}/>
            <a href="https://www.10vows.com/web/agreement/user" target="_blank" rel="noreferrer">服务使用协议 </a>
          </div>
          <div className={css.icp}>
            <a href="https://res.10vows.com/upload/202201/12/6KsgzVsfU1ksjvaS96TzhS7.jpg" target="_blank"
               rel="noreferrer">软件著作权</a>
            <a href="https://beian.miit.gov.cn/">京ICP备2021029406号-3</a>
            <span>京公网安备11011202003174号</span>
            <span>互联网宗教信息服务许可证：京（2022）0000071</span>
          </div>
          <div className={css.copyright}>Copyright {(new Date()).getFullYear()} Tenvows . All Rights Reserved.</div>
        </div>
        <div className={css.r}>

          <div className={css.qrcode}>
            <div className={css.code}>
              <img src={code} alt="" style={{padding: 2}}/>
            </div>
            <div className={css.name}>APP下载</div>
          </div>

          <div className={css.qrcode}>
            <div className={css.code}>
              <img src={code2} alt=""/>
            </div>
            <div className={css.name}>十愿智库微信</div>
          </div>

        </div>
      </div>
    </div>
  )
}
