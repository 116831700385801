import React, {useEffect} from 'react';
import css from './index.module.scss'
import bg from './bg.png'

export default function () {
  useEffect(() => {
    document.title = `关于我们_十愿网`
    // document.getElementsByName('keywords')[0].content = `关于我们, 十愿网`
    // document.getElementsByName('description')[0].content = `关于我们`
  }, [])

  return (
    <div className={css.aboutPage}>
      <img className={css.banner} src={bg} alt=""/>
      <div className={css.about}>
        <div className={css.title}>关于我们</div>
        <p className={css.p}>我们由凤凰网佛教核心团队成员创立，秉承“传承中华文化，愿世界更美好”的理念，致力于弘扬以儒释道为核心的中华文化传统和融汇中西、贯穿古今的人类共同护持的人文思想，以文载道、以文传声、以文化人，向全球华人展示丰富多彩、生动立体的文化大国形象。</p>
        <p className={css.p}>十愿APP是我们旗下智库型网络平台，下设百科、智库、人文、艺术、读书、视频等内容频道，构建由百个原生专题组成的优势内容库。在发展战略上，将以共建人类精神家园为己任，推动中华文化与世界对话，在文化、历史、国学、艺术等领域为全球华人提供优质新媒体内容与服务。</p>
        <p className={css.p}>十愿号作为业界优质内容品质平台，致力于将平台打造为当下互联网最具人本关怀，最具意气风骨的平台，愿中国文化惠泽天下，愿世界更美好，将是这个平台永远不变的追求。</p>
      </div>
    </div>

  )
}
