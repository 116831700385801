import React from 'react';
import { history } from 'libs';
import css from './index.module.scss';
import iconLogo from './icon-logo.png';

export default function Footer() {
  function go(url) {
    document.body.scrollIntoView()
    history.push(url)
  }

  return (
    <div className={css.footer}>
      <div className={css.links}>
        <img src={iconLogo} style={{width: 48, height: 48}} alt="logo"/>
        <a onClick={() => go('/m')}>首页</a>
        <a href="https://mp.10vows.com" target="_blank" rel="noreferrer">创作平台</a>
        <a onClick={() => go('/m/download')}>APP下载</a>
      </div>
      <div className={css.links}>
        <a onClick={() => go('/m/about')}>关于我们</a>
        <a href="https://res.10vows.com/upload/202201/12/6KsgzVsfU1ksjvaS96TzhS7.jpg" target="_blank" rel="noreferrer">软件著作权</a>
        <a href="https://www.10vows.com/web/agreement/privacy" target="_blank" rel="noreferrer">隐私协议</a>
        <a href="https://www.10vows.com/web/agreement/user" target="_blank" rel="noreferrer">服务使用协议 </a>
      </div>
      <div className={css.icp}>
        <a href="https://beian.miit.gov.cn/">京ICP备2021029406号-3</a>
        <span>京公网安备11011202003174号</span>
        <span>互联网宗教信息服务许可证：京（2022）0000071</span>
        <span>Copyright 2021 Tenvows . All Rights Reserved.</span>
      </div>
    </div>
  )
}
